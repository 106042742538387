import React, {createContext, useContext, useCallback, useState} from "react";
import {isMobileOnly} from "react-device-detect";

import {PopupGlobalDialog} from "../../widgets";
import {useSnackbar} from "notistack";

const initialState = {
  isOpen: false,
  title: "",
  message: "",
  action: "",
  alert: () => {
  },
  closeAlertPopup: () => {
  },
};

export const NotifyContext = createContext({
  ...initialState,
});


export const NotifyContextProvider = ({children, fix}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [action, setAction] = useState("");

  const showAlertPopup = useCallback((message, title, action) => {
    setIsOpen(true);
    setTitle(title);
    setMessage(message);
    setAction(action || "");
  }, []);

  const closeAlertPopup = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <NotifyContext.Provider
      value={{
        isOpen,
        title,
        message,
        action,
        alert: showAlertPopup,
        closeAlertPopup,
      }}
    >
      {children}
      <PopupGlobalDialog fix={fix}/>
    </NotifyContext.Provider>
  );
};

export const useNotifications = (isUseSnackbar = true) => {
  let context = useContext(NotifyContext);
  const {enqueueSnackbar} = useSnackbar();
  if (!isMobileOnly) { //PC默认都是使用Snackbar,如果不使用要指定isUseSnackbar为false,如Login369;
    if (isUseSnackbar) {
      context.alert = enqueueSnackbar;
    }
  }
  return context; //如果不使用Snackbar 必须使用NotifyContextProvider将组件包裹
};
