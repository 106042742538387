import {createContext} from "react";
import config from "../../../config/config";

export const initRegisterContext = {
  step: 1,
  username: '',
  password: '',
  password2: '',
  phone: '',
  code: '',
  areacode: config.features.PhonePrefix,
  upper: localStorage.getItem("upper"),
  promotionId: config.affiliateId ? config.affiliateId : localStorage.getItem("promotionId"),
  tnc: true,
  securityKey: '',
  promoteType: 'a', // a为默认的代理推荐码 p为新的玩家推荐码(全民代理)
}

export const RegisterContext = createContext({
  formData: initRegisterContext,
  setFormData: () => {
  },
});