import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {accountAction} from "../../../api-services/actions/accountAction";

export function useAccountStore() {
  const dispatch = useDispatch();
  const getAccountInfo = useCallback(
    async () => {
      return dispatch(accountAction.getAccountInfo());
    });
  const accountLogin = useCallback(
    async (username, password) => {
      return dispatch(accountAction.accountLogin(username, password));
    });
  
  return {
    getAccountInfo,
    accountLogin
    
    
  };
}

