import React from "react";
import {useState} from "react";
import styled from "styled-components";
import i18n from "../../../../../utils/i18n";
import {useEffect} from "react";
import Input from "../../input";

const TextfieldWrapper = styled.div`
  background: ${({theme}) => theme.new_textfieldBackground};
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  gap: 20px;
  border-radius: ${({theme}) => theme.new_borderRadius};
`;

const LeftSide = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

const TheTextfield = styled.input`
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  flex: 1;
  color: ${({theme}) => theme.new_textfieldText};

  &::-webkit-input-placeholder {
    color: ${({theme}) => theme.new_textfieldPlaceholder};
  }

  pointer-events: ${({disabled}) => (disabled ? "none" : "auto")};
`;

const Button = styled.div`
  background: ${({theme}) => theme.new_primary};
  font-size: 13px;
  padding: 5px 10px;
  min-width: fit-content;
  border-radius: ${({theme}) => theme.new_borderRadius};

  ${({disabled}) =>
          disabled
                  ? `
  background: #58585a;
  color: #fff;
  cursor: not-allowed;
  pointer-events: none;
  border:none;
  `
                  : `
  cursor: pointer;
  `}
`;

const VerificationV4 = (
  {
    value,
    placeholder,
    countdown,
    onChange,
    onClick,
  }) => {
  return (
    <Input>
      <TextfieldWrapper>
        <LeftSide>
          <TheTextfield
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            autoComplete="off"
          />
        </LeftSide>
        <Button
          disabled={countdown > 0}
          size="s"
          onClick={onClick}
        >
          {countdown
            ? i18n.t("common.btnTokenResendLabel") + "(" + countdown + "s)"
            : i18n.t("common.msgSendToken")}
        </Button>
      </TextfieldWrapper>
    </Input>
  );
};

export default VerificationV4;
