import React from "react";
import {isMobileOnly} from "react-device-detect";

import i18n from "../../../../utils/i18n";

import {Button} from "../../uis";
import {useDetectDialogClose, useNotifications} from "../../hooks";
import DialogPopup from "../Dialog/DialogPopup";
import {DialogContainer, DialogContent, DialogTitle} from "../Dialog/dialogStyles";
import styled from "styled-components";


/*
const ButtonConfirmMobile = styled.div`
  width: ${(props) => (i18n.language === "ta-IN" ? "auto" : "250px")};
  height: 90px;
  border-radius: 20px;
  font-size: 34px;
  text-align: center;
  padding-top: 24px;
  margin: 10px auto;
  background-image: linear-gradient(to top,
  ${({theme}) => theme.primaryGradient},
  ${({theme}) => (theme.wapPrimary ? theme.wapPrimary : theme.primary)});
  color: #ffffff;
`;
const ButtonConfirmPc = styled.div`
  width: ${(props) => (i18n.language === "ta-IN" ? "auto" : "100px")};
  height: 40px;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  padding-top: 12px;
  margin: 10px auto;
  background-image: linear-gradient(to top,
  ${({theme}) => theme.primaryGradient},
  ${({theme}) => (theme.wapPrimary ? theme.wapPrimary : theme.primary)});
  color: #ffffff;
`;
const ButtonConfirm = isMobileOnly ? ButtonConfirmMobile : ButtonConfirmPc;
*/


const ButtonWrapper = styled.div`
  text-align: center;
`;
const ModalButtons = ({fix, handleClose, message, action}) => {
  if (message === "无法连接，请检查您的网络") {
    return (
      <Button fullWidth onClick={() => window.location.reload()} label={i18n.t("common.confirm")} fix={fix}>
        {i18n.t("common.reload")}
      </Button>
    );
  } else {
    return (
      <>
        {/*之前用的 <ButtonConfirm*/}
        <Button onClick={handleClose} width={260}  fix={fix}>
          {i18n.t("common.confirm")}
        </Button>
        
        {action && <div onClick={handleClose}>{action}</div>}
      </>
    );
  }
};

const PopupGlobalDialog = ({fix}) => {
  const {isOpen, title, message, action, closeAlertPopup} = useNotifications();
  useDetectDialogClose(isOpen, closeAlertPopup);
  return (
    <DialogPopup fix={fix} open={isOpen} onClose={closeAlertPopup}>
      <DialogContainer fix={fix}>
        <DialogTitle fix={fix}>{title}</DialogTitle>
        <DialogContent fix={fix}>{message}</DialogContent>
        <ButtonWrapper>
          <ModalButtons fix={fix} handleClose={closeAlertPopup} message={message} action={action}/>
        </ButtonWrapper>
      
      </DialogContainer>
    </DialogPopup>
  );
};

export default React.memo(PopupGlobalDialog);
