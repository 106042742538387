import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Header from "../Share/Header";

import i18n from "../../../utils/i18n";
import config from "../../../config/config";

import { useCustomMsg, useBaseInfo  } from "../../../components/Common/hooks/pages/profile"
const Container = styled.div`
  width: 100%;
  overflow: hidden;

  height: 100%;
`;

const BlockLabel = styled.div`
  font-size: ${() => (config.brand === "WGHK" ? `30px` : `32px`)};
  font-weight: 600;

  color: #2b2b2b;
  margin: 30px 0 10px 60px;
`;
const BlockContainer = styled.div`
  background-color: #fff;
  width: 700px;
  padding: 30px 0;
  border-radius: 20px;
  margin: 30px auto;
`;
const ItemContainer = styled.div`
  min-height: 100px;

  margin: 30px 0 0 30px;
`;
const ItemIconContainer = styled.div`
  width: 48px;
  float: left;
  margin: 0 10px 0 30px;
`;
const ItemIcon = styled.div`
  height: 48px;
  margin-top: 20px;
  width: 48px;
  mask: url(${(props) => props.icon}) no-repeat left center;
  mask-size: contain;
  background: ${({ theme }) => theme.new_gradientBackground ?? theme.primary};
`;
const ItemContentContainer = styled.div`
  float: left;
  margin: 0 0 0 10px;
`;
const ItemTitle = styled.div`
  font-size: ${() => (config.brand === "WGHK" ? `28px` : `32px`)};
  font-weight: ${() => (config.brand === "WGHK" ? `400` : `normal`)};

  color: #2c2c2c;
`;
const NotSetLabel = styled.div`
  margin-top: 5px;
  font-size: ${() => (config.brand === "WGHK" ? `28px` : `32px`)};
  //font-weight: ${() => (config.brand === "WGHK" ? `400` : `500`)};
  color: #afafaf;
`;
const SettedLabel = styled(NotSetLabel)`
  color: ${({ theme }) => theme.secondary};
`;
const BtnBack = styled.div`
  height: 100px;

  width: 50px;
  background: url(${process.env.PUBLIC_URL +
    `/assets/images/wapp/btn-back.png`})
    no-repeat right center;
  float: right;
  margin: 0 50px 0 0px;
`;
const ContentItem = ({
  icon,
  title,
  isConfigured,
  onClickEvent,
  configuredValue,
  category,
  disabled
}) => {
  var configureElement = isConfigured ? (
    <SettedLabel>{configuredValue}</SettedLabel>
  ) : (
    <NotSetLabel>{i18n.t("profile.notSet")}</NotSetLabel>
  );
  var disable = false;
  if (category === "member_phone") {
    if (config.features.Profile_NotAllowChangePhone && isConfigured) {
      disable = true;
    }
  }
  return (
    <ItemContainer onClick={!disable && !disabled && onClickEvent}>
      <ItemIconContainer>
        <ItemIcon icon={icon}></ItemIcon>
      </ItemIconContainer>
      <ItemContentContainer>
        <ItemTitle>{title}</ItemTitle>
        {configureElement}
      </ItemContentContainer>
      {!disable && !disabled && <BtnBack></BtnBack>}
    </ItemContainer>
  );
};

const CustomMessaging = ({
  getCustomMessagingIcon,
  handleNavigate
}) => {
  const { list } = useCustomMsg()
  return list.length ? (
    <BlockContainer>
      <BlockLabel>{i18n.t("profile.otherInfo")}</BlockLabel>
      {
        list.map(item => (
          <ContentItem
            icon = {getCustomMessagingIcon(item.name)}
            title= {item.label}
            isConfigured={item.isVerified}
            onClickEvent={() => {
              handleNavigate(item.path);
            }}
            configuredValue={item.showAccountName}
          ></ContentItem>
        ))
      }
    </BlockContainer>
  ) : (
    <></>
  )
};

const BaseInfoList = ({accountInfo, handleNavigate}) => {
  const { baseInfoList, getBaseInfoValue } = useBaseInfo()
  const setConfigured = (type) => {
    if (type === 'member_name') {
      return !([null, ""].includes(accountInfo['member_name']))
    }
    return !!accountInfo[type]
  }
  return baseInfoList.filter(item => item.show).map((infoItem, infoIndex) => {
    const comProps = infoItem.comProps
    return (
      <ContentItem
        key={infoIndex}
        icon={comProps.icon}
        title={comProps.title}
        isConfigured={setConfigured(comProps.attr)}
        onClickEvent={() => {
          handleNavigate(comProps.path);
        }}
        configuredValue={getBaseInfoValue(comProps.attr)}
        category={comProps.attr}
      ></ContentItem>
    )
  })
}


class Profile extends Component {
  state = {};
  componentDidMount() {
    if (this.props.account.token === null) {
      this.props.appHistory.replace("/login");
    }
  }
  handleBackClick = () => {
    this.props.appHistory.goBack();
  };
  handleNavigate = (path) => {
    this.props.appHistory.push(path);
  };
  isConfigured(value) {
    if (value) return true;
    return false;
  }

  getStatus = () => {
    var currentFormStatus = this.props.account.info.identityformstatus;
    var hasSubmitted = this.props.account.info.identityformid ? true : false;

    var status = "";

    if (currentFormStatus === 0 && hasSubmitted === false) {
      //api沒給表單id ，表示可以上傳
      return (status = "no");
    }
    if (
      (currentFormStatus === 1 && hasSubmitted === true) ||
      currentFormStatus === 1
    ) {
      return (status = "yes");
    }
    if (
      (currentFormStatus === 2 && hasSubmitted === true) ||
      currentFormStatus === 2
    ) {
      //有給表單id但是狀態是拒絕，也可以上傳
      return (status = "rejected");
    }
    if (currentFormStatus === 0 && hasSubmitted === true) {
      //有給表單id但狀態沒驗證，不能上傳
      return (status = "verifying");
    }
    return status;
  };

  getCustomMessagingIcon = (platform) => {
    switch (platform) {
      case "qq":
        return process.env.PUBLIC_URL + `/assets/images/wapp/icon-qq.png`;
      case "wechat":
        return process.env.PUBLIC_URL + `/assets/images/wapp/icon-wechat.png`;
      case "facebookmessenger":
        return process.env.PUBLIC_URL + `/assets/images/wapp/icon-fb.svg`;  
      default:
        return (
          process.env.PUBLIC_URL + `/assets/images/wapp/icon-${platform}.svg`
        );
    }
  };
  render() {
    return (
      <Container>
        <Header
          token={null}
          balance={0}
          background={true}
          onBackClick={this.handleBackClick}
          title={i18n.t("profile.title")}
        ></Header>

        <div style={{ marginBottom: "-30px" }}>
          <BlockContainer>
            <BlockLabel>{i18n.t("profile.basicInfo")}</BlockLabel>
            <BaseInfoList accountInfo={this.props.account.info} handleNavigate={this.handleNavigate}></BaseInfoList>
          </BlockContainer>

          <CustomMessaging getCustomMessagingIcon={this.getCustomMessagingIcon} handleNavigate={this.handleNavigate}></CustomMessaging>
        </div>

        <BlockContainer>
          <BlockLabel>{i18n.t("profile.securityInfo")}</BlockLabel>
          <ContentItem
            icon={process.env.PUBLIC_URL + `/assets/images/wapp/icon-unlock.png`}
            title={i18n.t("profile.password.title")}
            isConfigured={true}
            onClickEvent={() => {
              this.handleNavigate("/profile/changepassword");
            }}
            configuredValue=""
          ></ContentItem>


          {/* {config.brand !== 'HKJP' && config.brand !== 'GPI'
            ? ContentItem(
                process.env.PUBLIC_URL + `/assets/images/wapp/icon-piggy.png`,
                i18n.t('profile.withdrawalPassword.title'),
                this.isConfigured(this.props.account.info.member_safe_password),
                () => {
                  if (this.props.account.info.check_pass_email === false) {
                    this.props.alert(
                      '',
                      i18n.t('profile.msgPlsBindEmailAddress')
                    )
                    this.handleNavigate('/profile/email')
                  } else {
                    if (
                      this.props.account.info.member_safe_password === 'set'
                    ) {
                      this.handleNavigate('/profile/changewithdrawalpassword')
                    } else {
                      this.handleNavigate('/profile/withdrawalpassword')
                    }
                  }
                }
              )
            : null} */}
        </BlockContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};
export default connect(mapStateToProps, null)(withRouter(Profile));
