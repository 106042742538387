/**
 * 代码来源于wap-t1: ui/textfield
 */

import React from "react";
import styled from "styled-components";
import Icon from "../../icon";
import Input from "../../input";
import InputWrapper from "../../inputwrapper";


const LeftSide = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

const Textfield = ({
                     icon,
                     value,
                     onChange,
                     type,
                     placeholder,
                     maxLength,
                     disabled,
                     action,
                     label,
                     onClick,
                     remark,
                   }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  
  return (
    <Input label={label} remark={remark}>
      <InputWrapper
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        disabled={disabled}
      >
        <LeftSide>
          {icon && <Icon icon={icon} inInput value={value} />}
          <input
            disabled={disabled}
            type={
              type === "password" ? (showPassword ? "text" : "password") : type
            }
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
              if (onChange) {
                if (maxLength) {
                  if (
                    e.target.value.length <= maxLength ||
                    e.target.value.length < value.length
                  ) {
                    onChange(e);
                  }
                } else {
                  onChange(e);
                }
              }
            }}
          />
        </LeftSide>
        {type === "password" && (
          <Icon
            icon={showPassword ? "hide-password.png" : "show-password.png"}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          />
        )}
        {action && action}
      </InputWrapper>
    </Input>
  );
};

export default Textfield;
