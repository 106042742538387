import React, { useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../../utils/i18n";
import styled from "styled-components";
import { GeneralService } from "../../../../api-services/services/GeneralService";
import ErrorSwitcher from "../../utilc/ErrorSwitcher";
import { generalAction } from "../../../../api-services/actions/generalAction";
import { uiAction } from "../../../../api-services/actions/uiAction";
import {Icon} from "../../uis";

const Lang = styled.div`
  padding: 15px 15px 15px 60px;
  ${({selected, theme}) => {
    return selected ? `color: ${theme.new_primary}` : "";
  }}
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0 0 12.5px 12.5px;
`;

const Arrow = styled(Icon)`
  transform: rotate(90deg);
`;

const SelectedLang = styled.div``;

const Flag = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${({ country }) => {
  return `url(${
    process.env.PUBLIC_URL + `/assets/images/flags/${country}.png`
  }) no-repeat center`;
}};
`;

const Selection = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 12.5px;
  gap: 15px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  width: max-content;

  ${({ open }) => {
  return open
    ? `
    border-radius: 12.5px 12.5px 0 0 ;
    width: 100%;
    `
    : "";
}}
`;

const DropdownLanguagePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
`;

const LinearLanguagePickerWrapper = styled.div`
  display: inline-flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

const Languages = styled.div`
  text-wrap: nowrap;
  color: ${({ selected, theme }) =>
  selected === true ? theme.new_primary : theme.text};
`;

const LanguagePicker = ({
                          layout,
                          general,
                          getBanner,
                          getMarquee,
                          getAnnouncement,
                        }) => {
  const { brandLanguage } = general;

  const [open, setOpen] = useState(false);

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang.culturecode);
    getBanner();
    getMarquee(lang.culturecode);
    getAnnouncement(lang.culturecode);

    GeneralService.GetLocalization().then((response) => {
      if (response.IsSuccess) {
        ErrorSwitcher.setTranslation(response.data);
      }
    });
  };

  const LinearLangPickeer = () => {
    return (
      <LinearLanguagePickerWrapper>
        {brandLanguage.map((lang, index) => {
          return (
            <>
              <Languages
                selected={i18n.language === lang.culturecode}
                key={index}
                onClick={() => {
                  handleChangeLanguage(lang);
                }}
              >
                {i18n.t("lang." + lang.culturecode)}
              </Languages>
              {brandLanguage.length - 1 !== index ? <div> | </div> : ""}
            </>
          );
        })}
      </LinearLanguagePickerWrapper>
    );
  };

  const DropdownLangPicker = () => {
    return (
      <DropdownLanguagePickerWrapper>
        <Selection
          onClick={() => {
            setOpen(!open);
          }}
          open={open}
        >
          <Flag country={i18n.language}></Flag>
          <SelectedLang>{i18n.t("lang." + i18n.language)}</SelectedLang>
          <Arrow icon="chev-right.png" size="15" followTextColor />
        </Selection>
        {open && (
          <Menu>
            {brandLanguage.map((lang, index) => {
              return (
                <Lang
                  key={index}
                  onClick={() => {
                    handleChangeLanguage(lang);
                    setOpen(false);
                  }}
                  selected={i18n.language === lang.culturecode}
                >
                  {i18n.t("lang." + lang.culturecode)}
                </Lang>
              );
            })}
          </Menu>
        )}
      </DropdownLanguagePickerWrapper>
    );
  };

  const switchLanguagePickerLayout = (layout) => {
    switch (layout) {
      case "linear":
        return <LinearLangPickeer />;
      case "dropdown":
        return <DropdownLangPicker />;
      default:
        return <LinearLangPickeer />;
    }
  };

  return switchLanguagePickerLayout(layout);
};

const mapStateToProps = (state) => {
  return {
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBanner: () => dispatch(generalAction.getBanner()),
    getMarquee: (language) => dispatch(uiAction.getMarquee(language)),
    getAnnouncement: (language) => dispatch(uiAction.getAnnouncement(language)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker);
