/**
 * 来源于wap-t1: ui/button/
 */
import React from "react";
import styled from "styled-components";
import Icon from "../../icon";
const CustomButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px transparent;
  border-radius: ${({ theme }) => theme.new_borderRadius};
  ${({ variant, theme }) => switchVariant(variant, theme)};
  ${({ size }) => sizeSwitcher(size)};
  ${({ disabled, theme }) =>
  disabled
    ? `
    background: rgba(${theme.new_textRgb}, 0.2);
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
    `
    : `
    cursor: pointer;
    `}

  ${({ gray, theme }) =>
  gray &&
  `
    background: rgba(${theme.new_textRgb}, 0.2);
    color: #fff;
    `}



  max-width: ${({ maxContent }) => {
  return maxContent ? "fit-content" : "100%";
}};

  ${({ withBg }) => {
  return (
    withBg &&
    `
      background: ${withBg}
    `
  );
}}
`;

const ButtonWrapper = styled.div`
  padding: ${({ margin }) => {
  return margin ? margin : "0px";
}};
`;

function switchVariant(variant, theme) {
  switch (variant) {
    case "outlined":
      return {
        background: "transparent",
        borderColor: theme.new_primary,
        color: theme.primaryHighContrast ? theme.new_primary : "#fff",
      };
    case "text":
      return {
        background: "transparent",
        color: theme.new_text,
      };
    
    default:
      return {
        background: theme.new_primary,
        color: theme.primaryHighContrast ? "#000" : "#fff",
      };
  }
}

function sizeSwitcher(size) {
  switch (size) {
    case "s":
      return {
        padding: "2px",
        fontSize: "8px",
        gap: "2px",
      };
    
    case "m":
      return {
        padding: "5px ",
        fontSize: "10px",
        minWidth: 70,
        gap: "4px",
      };
    default:
      return {
        padding: "14px",
        gap: "20px",
        fontSize: 15,
      };
  }
}

function iconSizeSwitcher(size) {
  switch (size) {
    case "s":
      return 8;
    case "m":
      return 10;
    default:
      return 20;
  }
}

const Button = ({
                  label,
                  children,
                  variant,
                  onClick,
                  disabled,
                  margin,
                  size,
                  maxContent,
                  icon,
                  followTextColor,
                  gray,
                  iconUseImage,
                  withBg,
                }) => {
  return (
    <ButtonWrapper margin={margin}>
      <CustomButton
        maxContent={maxContent}
        disabled={disabled}
        variant={variant}
        onClick={onClick}
        size={size}
        gray={gray}
        withBg={withBg}
      >
        {icon && (
          <Icon
            icon={icon}
            size={iconSizeSwitcher(size)}
            followTextColor={variant === "text" || followTextColor}
            iconUseImage={iconUseImage}
          />
        )}
        <div> {label??children}</div>
      </CustomButton>
    </ButtonWrapper>
  );
};

export default Button;
