/**
 * 来源于tgs-web-t1: Share/Button,后面再扩展
 */
import React from "react";
import styled from "styled-components";


function CustomButton({
                        width,
                        small,
                        marginTop,
                        grayMode,
                        float,
                        marginLeft,
                        marginRight,
                        disabled,
                        onClick,
                        label,
                        children,
                        noBorder,
                      }) {
  const StyleButton = styled.div`
    display: flex;
    justify-content:center;
    align-content: center;
    
    width: ${width ? (width === 'auto' ? '100%' : width + "px") : "347px"};
    height: ${small ? "32" : "46"}px;
    ${!noBorder?'border: solid 1px #ffffff':''}
    background-image: linear-gradient(to top,
    ${({theme}) => theme.primaryGradient},
    ${({theme}) => theme.primary});
    ${grayMode
            ? "    background-blend-mode: multiply, normal; background-image:  linear-gradient(to bottom, #bfbfbf, #bfbfbf), linear-gradient(to top, #797979, #c3c3c3);"
            : ""}

    border-radius: 6px;
    font-size: ${small ? "14" : "16"}px;
    color: #fff;
    ${disabled === true
            ? `pointer-events:none;background-image:  linear-gradient(to bottom, #bfbfbf, #bfbfbf), linear-gradient(to top, #797979, #c3c3c3);`
            : `
    &:hover {
      cursor: pointer;
      box-shadow: inset 1px 1px 9px 0  rgba(255, 255, 255, 0.75);
      ${
                    grayMode
                            ? "   background-image: linear-gradient(to top, #797979, #c3c3c3);"
                            : " "
            }
    }
    `}
  `;
  const ButtonLabel = styled.span`
    display: flex;
    align-items: center;
  `;
  return (
    <StyleButton onClick={onClick}>
      <ButtonLabel>{label ?? children}</ButtonLabel>
    </StyleButton>
  );
}

export default CustomButton;
