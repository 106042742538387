import React from "react";
import styled from "styled-components";
import {Close} from "@material-ui/icons";
import {scalePx, scaleVal, dialogPcCloseIconColor} from "../../../templates";

const CloseIconContainer = styled.div`
  position: absolute;
  top: 12.5px;
  right: 12.5px;
  width: ${scalePx(24)};
  height: ${scalePx(24)};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
`;


const CloseIcon = ({onClick, style, ...others}) => {
  return (
    <CloseIconContainer style={style} {...others}>
      <Close
        onClick={onClick}
        style={{
          color: dialogPcCloseIconColor(), fontSize: scaleVal(20)
        }}
      />
    </CloseIconContainer>
  );
};

export default CloseIcon;
