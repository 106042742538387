import React from "react";
import {isMobileOnly} from "react-device-detect";
import {Dialog} from '@material-ui/core';
import styled from "styled-components";
import {UpTransition} from "./Transition";
import CloseIcon from "../Popup/components/CloseIcon";
import i18n from "../../../../utils/i18n";

import {scalePx} from "../../templates";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background: ${({theme}) => theme.mainBackground};
    width: ${({fix}) => scalePx(300, fix)}; //t29 321px  250
    border-radius: ${({fix}) => scalePx(10, fix)};
  }
`;

const ChildContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;


const CloseIconExist = ({onClose}) => {
  return isMobileOnly ? <></> : <CloseIcon onClick={onClose}/>;
}

const DialogPopup = ({fix, open, onClose, children}) => {
  return (
    <StyledDialog fix={fix} open={open} onClose={onClose} /*TransitionComponent={UpTransition} */>
      <ChildContainer>{children}</ChildContainer>
      <CloseIconExist onClose={onClose}/>
    </StyledDialog>
  );
};

export default React.memo(DialogPopup);
