import { isMobileOnly } from "react-device-detect";
import { version, isWapV1, isWapV2 } from "..";
import { styleGlobal } from "./styleGlobal";
import { styleTab } from "./styleTab";
import { theme } from "../themes";


/** 缩放px (t1的手机版有缩放) */
export const scaleVal = (val) => {
  if (!isMobileOnly) {
    return val;
  } else {
    return {
      1: val * (isWapV1 ? 2 : 1),  //因为手机版型1做了缩放,所以要放大,才正常
      2: val * (isWapV2 ? 2 : 1)
    }[version] ?? val;
  }
}



/** 缩放px */
export const scalePx = (size, fix = false) => {
  if (!fix) {
    size = scaleVal(size);
  }
  return size + 'px';
}

/** 统一的圆角 */
export const borderRadius = () => {
  return {
    1: '16px',
    4: theme.new_borderRadius
  }[version];
}

export const dialogPcCloseIconColor = () => {
  return {
    1: 'black',
    4: 'white'
  }[version];
}


/** 常规主颜色颜色 */
export const primaryColor = () => {
  return {
    1: theme.primary ?? theme.new_primary,
    4: theme.new_primary
  }[version];
}


/** 常规正文本字体颜色 */
export const textPrimaryColor = () => {
  return {
    1: styleGlobal.primaryText ?? (theme.new_primaryText ?? theme.primaryText),
    4: theme.new_textfieldText ?? theme.new_primary,
  }[version];
}

/** 常规副字体颜色 */
export const textSecondColor = () => {
  return {
    1: isMobileOnly ? theme.wapSecondary : theme.secondary,
    4: ""
  }[version];
}

/** 输入框背景颜色 */
export const textfieldBackground = () => {
  return {
    1: theme.buttonText,
    4: theme.new_textfieldBackground
  }[version];
}

/** 输入框字体颜色 */
export const textfieldColor = () => {
  return {
    1: theme.new_textfieldText ?? theme.primaryText, //isMobileOnly ? theme.wapSecondary :
    4: theme.new_textfieldText
  }[version];
}

export const textfieldPlaceholderColor = () => {
  return {
    1: theme.new_textfieldText,
    4: theme.new_textfieldText
  }[version];
}


/** 充值模式按钮 背景颜色 */
export const tabBackground = (theme, active) => {
  if (active) {
    return { 1: theme.primary, 4: theme.new_primary }[version];
  } else {
    return "transparent";
  }
}

/** 充值模式按钮 背景边框 */
export const tabButtonBorder = (theme, active) => {
  if (active) {
    return { 1: styleTab.tabBorderActive, 4: theme.new_primary }[version];
  } else {
    return { 1: styleTab.tabBorder, 4: theme.new_text }[version];
  }
}

/** 充值模式按钮 字体 */
export const tabButtonColor = (theme, active) => {
  if (active) {
    return { 1: styleTab.tabTextActive, 4: theme.new_body }[version];
  } else {
    return { 1: styleTab.tabText, 4: theme.new_text }[version];
  }
}