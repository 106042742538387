import React from "react";
import styled from "styled-components";
import Overlay from "../overlay";
import Icon from "../icon";

const DraweContainer = styled.div`
  max-height: 100vh;
  min-height: 100vh;
  width: ${({ theme }) => theme.new_drawerWidth};
  position: absolute;
  ${({ position }) => {
  return `${position}: 0`;
}}
`;

const DummyWrapper = styled(DraweContainer)`
  background: #000;
`;

const drawerBackground = (variant, theme) => {
  switch (variant) {
    case "gradient":
      return {
        background: ` linear-gradient(150deg, rgba(${theme.new_alternateColorRgb},1) 0%, rgba(${theme.new_primaryRgb},1) 100%);`,
      };
    default:
      return {
        background: `rgba(${theme.new_primaryRgb}, 0.5)`,
      };
  }
};

const DrawerWrapper = styled(DraweContainer)`
  overflow: auto;
  ${({ theme }) => {
  return drawerBackground(theme.new_drawerBackgroundType, theme);
}}

  ${({ showBack }) => {
  return showBack ? `padding-top: 20px;` : "";
}}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 80px;
`;

const Back = styled.div`
  position: absolute;
  top: 0;

  ${({ position }) => {
  if (position === "right") {
    return `
      transform: translateY(50%) scale(-1);
      left: 15px;
      `;
  } else if (position === "left") {
    return `
      transform: translateY(50%) scale(1);
      right: 15px;
      `;
  }
}}
`;

const Drawer = ({
                  open,
                  onClose,
                  children,
                  position = "right",
                  showBack = false,
                }) => {
  return (
    <Overlay open={open} onClose={onClose}>
      <DummyWrapper position={position} />
      <DrawerWrapper
        position={position}
        onClick={(e) => {
          e.stopPropagation();
        }}
        showBack={showBack}
      >
        {showBack && (
          <Back position={position} onClick={onClose}>
            <Icon icon="i-back.png" size="20"></Icon>
          </Back>
        )}
        <Content>{children}</Content>
      </DrawerWrapper>
    </Overlay>
  );
};

export default Drawer;
