import {useState} from "react"

import {initRegisterContext, RegisterContext} from "../contexts";

/**
 * 存款表單共用狀態 Provider
 */
export const RegisterProvider = ({children}) => {
  const [formData, setFormData] = useState(initRegisterContext);
  
  return (
    <RegisterContext.Provider value={{formData, setFormData}}>
      {children}
    </RegisterContext.Provider>
  );
};
