/**
 * 代码来源于tgs-web-t1: Wap/Share/Textbox
 */
import React from "react";
import styled from "styled-components";

import config from "../../../../../config/config";
import {textfieldColor} from "../../../templates";

const CustomText = styled.div`
  //width: 700px;
  //padding: 20px 20px 0 20px;
  //margin: 20px auto;
  display: flex;
  flex-direction: column;
`;

const CustomLabel = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-left: 3px;
  color: ${({black, theme}) => {
    return black
            ? "#000"
            : theme.wapSecondary
                    ? theme.wapSecondary
                    : theme.secondary;
  }};
`;

const CustomInput = styled.input`
  font-size: 28px;
  //margin-top: 18px;
  border-radius: 16px;
  min-height: 71px;
  padding: 16px 32px;
  font-weight: 500;
  border: 0;
  display: flex;
  flex: 1;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #c3c3c3;
    font-size: 28px;
  }

  &:disabled {
    background: #fff;
    opacity: 1;
  }
;
  color: ${({theme}) => textfieldColor(theme)};

  ${() => {
    if (config.is369) {
      return `
        background-color: #353D4FFF;
      `
    }
    ;
  }}
`;

const CustomDisplayer = styled.div`
  font-size: 28px;
  margin-top: 18px;
  border-radius: 16px;
  min-height: 71px;
  padding: 16px 32px;
  font-weight: 500;
  border: 0;
  display: flex;
  align-items: center;
  flex: 1;
  background: #fff;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Textbox = ({
                   onClick,
                   black,
                   required,
                   label,
                   custom,
                   color,
                   type,
                   value,
                   placeholder,
                   disabled,
                   onChange,
                   onBlur,
                   maxLength,
                 }) => {
  return (
    <CustomText
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <LabelContainer>
        <CustomLabel black={black}>
          {required && <span style={{color: "red"}}>*</span>}
          {label}
        </CustomLabel>
        {custom && <div>{custom}</div>}
      </LabelContainer>
      {onClick ? (
        <CustomDisplayer>{value}</CustomDisplayer>
      ) : (
        <CustomInput
          style={color ? {color: color} : {}}
          type={type ? type : "text"}
          value={value}
          placeholder={placeholder}
          disabled={disabled ? disabled : false}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength ? maxLength : 50}
        ></CustomInput>
      )}
    </CustomText>
  );
};

export default Textbox;
