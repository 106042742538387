import React, { useState, useEffect, useMemo, useContext, createContext } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import config from "../../../../../config/config";
import i18n from "../../../../../utils/i18n";
import { useRegisterStore } from "../../../stores/useRegisterStore";
import { RegisterContext } from "../../../contexts";
import { useNotifications } from "../../../hooks";
import { useWalletStore, useAccountStore, useGeneralStore } from "../../../stores";

import { ErrorSwitcher } from "../../../utilc";
import { AccountService } from "../../../../../api-services/services/AccountService";
import { accountAction } from "../../../../../api-services/actions/accountAction";
import { RegisterNextStepEnum } from "../../../constants/CommonEnum";
import { isBrand369VN, IsWithSMSCode } from "../../../templates";

/** 369埋点 */
const try369Point = () => {
  const fbq = window?.fbq
  if (typeof fbq === 'function') {
    fbq('track', 'CompleteRegistration');
  }
}

export const useRegisterForm = () => {
  const params = useParams();
  let { alert } = useNotifications();
  const { getInvitationCode } = useGeneralStore();
  const general = useSelector((state) => state.general);
  const { formData, setFormData } = useContext(RegisterContext);
  const history = useHistory();
  const [countdown, setCountdown] = React.useState(0);
  // const [promoteType, setPromoteType] = React.useState(defaultPromoteType);
  const [hadSend, setHadSend] = React.useState(false);
  //const [upper, setUpper] = useState(localStorage.getItem("upper"));
  //const [promotionId, setPromotionId] = React.useState(config.affiliateId ?? localStorage.getItem("promotionId"));
  const [fromCustomDomain, setFromCustomDomain] = React.useState(false);
  const [prefill, setPrefill] = React.useState(localStorage.getItem("promotionId") || localStorage.getItem("upper"));


  useEffect(() => {
    console.log("useRegisterForm v9.18.2");
    initAreacode();
    initPromotionId();
    initInvitationCode();
  }, []);

  const initInvitationCode = () => {
    if (general.brand.hasInvitationCode) {
      getInvitationCode().then((result) => {
        if (result.IsSuccess && result.data.invitationCode) {
          setFormData({ ...formData, promoteType: "a", promotionId: result.data.invitationCode })
          setPrefill(true);
          setFromCustomDomain(true);
        }
      });
    }
  }
  const initPromotionId = () => {
    if (params.promotionid || localStorage.getItem("promotionId")) {
      const promotionId = params.promotionid ? params.promotionid : localStorage.getItem("promotionId");
      if (promotionId) {
        setFormData({ ...formData, promotionId: promotionId });
      }
    }
  }

  const initAreacode = () => {
    setFormData({
      ...formData,
      areacode: config.features.PhonePrefix
    })
  }

  const isRegisterShowPhone = config.features.Register_ShowPhone;
  //const isSMSInRegister = config.features.SMSVerificationInRegister;

  //hooks
  const game = useSelector((state) => state.game);
  const { doInitPhone, doCodeValidate, newRegister } = useRegisterStore();
  const { accountLogin } = useAccountStore();

  // const {getAccountInfo} = useAccountStore();
  // const {getBankCardList, getBalanceList, getGameBalance, getAutoTransferStatus, getWithdrawalChannel} = useWalletStore();
  useEffect(() => {
    if (countdown > 0)
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
  }, [countdown]);


  const checkInfo = (isLogin = false, isGetCode = false) => {
    if (isLogin) {
      if (formData.username === "") {
        alert(i18n.t("web.login.errorMsg.emptyUsername"));
        return false;
      }
      if (formData.password === "") {
        alert(i18n.t("web.login.errorMsg.emptyPassword"));
        return false;
      }
    } else {
      var expr = /^[a-zA-Z0-9]{6,16}$/;
      if (!expr.test(formData.username)) {
        alert(i18n.t("common.msgRegisterUserNameInvalidOri"));
        return false;
      }
      expr = /^.{6,20}$/;
      if (!expr.test(formData.password)) {
        alert(i18n.t("common.msgRegisterPassInvalid"));
        return false;
      }

      if (formData.password !== formData.password2) {
        alert(i18n.t("common.msgRegisterPassMatchInvalid"));
        return false;
      }

      //需要区分每个品牌区域电话号码的长度规则
      if (isRegisterShowPhone || IsWithSMSCode) {
        if (isBrand369VN) {
          expr = new RegExp(`^0[0-9]{${config.features.MAX_PHONE_LENGTH - 1}}$`);
          if (!expr.test(formData.phone)) {
            alert(i18n.t("common.noReachZeroStartPhone").replace(/{maxlength}/g, config.features.MAX_PHONE_LENGTH || 10));
            return false;
          }
        } else {
          expr = new RegExp(`^[0-9]{8,${config.features.MAX_PHONE_LENGTH || 10}}`);
          if (!expr.test(formData.phone)) {
            alert(i18n.t("common.noReachMin").replace(/{maxlength}/g, config.features.MAX_PHONE_LENGTH || 10));
            return false;
          }
        }
      }
      if (IsWithSMSCode && formData.code === "" && !isGetCode) {
        alert(i18n.t("common.msgEnterCode"));
        return false;
      }
    }
    return true;
  }
  const goDoInitPhone = () => {
    doInitPhone(formData.areacode, formData.phone).then((response) => {
      if (response.IsSuccess) {
        alert(i18n.t("common.msgTokenSent"));
        setFormData({ ...formData, securityKey: response.data.stepResponse });
        setHadSend(true);
        setCountdown(60); 
      } else {
        alert(response.info) 
      }
    })
  }

  const createPlayer = () => {
    return newRegister("CreatePlayer", {
      username: formData.username,
      pwd: formData.password,
      upper: formData.upper,
      invitationcode: formData.promotionId,
    });
  }

  /** 发送验证码 */
  const performGetCode = () => {
    if (countdown > 0) {
      return;
    }
    console.log('1')
    if (!checkInfo(false, true)) {
      return;
    } 
    if (hadSend) {
      goDoInitPhone(); 
    } else { 
      createPlayer().then((response) => {
        if (!response.IsSuccess) {
          alert(ErrorSwitcher.getTranslation(response.info));
        } else {
          if (response.data.isCompleted || response.data.stepResponse?.isnew === false) {
            alert(i18n.t("common.msgAlreadyExist"));
          } else { 
            AccountService.Login(formData.username, formData.password).then((response) => {
              if (response.IsSuccess) {
                goDoInitPhone();
              }
            });
          }
        }
      });
    }
  }

  /** 确认验证码并注册 */
  const performValidateCode = (onHandleLoginSuccess) => {
    doCodeValidate(formData.securityKey, formData.code).then((response) => {
      if (response.IsSuccess) {
        accountLogin(formData.username, formData.password).then((result) => {
          if (result.IsSuccess) {
            onHandleLoginSuccess();
          } else {
            alert(result.info); 
          }
        });
      } else {
        //暂时错误也登录 
        alert(response.info);
      }
    })

  }

  /**
   * 新登录-因为有参数不能在onclick直接调用,否则新一个参数会变e
   * @param isNewLogin 是否369的新登录
   * @param onHandleLoginSuccess 登录回调函数
   * @
   */
  const performNewRegister = async (isNewLogin = false, onHandleLoginSuccess = null) => {
    console.log("performNewRegister v9.26.1");
    if (!checkInfo()) {
      return;
    }

    if (!formData.tnc) {
      alert(i18n.t("common.msgRegisterTermAndCondition"));
      return;
    }

    if (IsWithSMSCode) {
      if (!hadSend) {
        alert(i18n.t('common.msgInvalidPhoneCode'))
        return
      }
      return performValidateCode(onHandleLoginSuccess);
    }
    console.log('performNewRegister-----1')
    //以下是非SMS注册流程
    createPlayer().then((response) => {
      if (!response.IsSuccess) {
        alert(ErrorSwitcher.getTranslation(response.info));
      } else {
        if (response.data.stepResponse?.isnew === false) {
          alert(i18n.t("common.msgAlreadyExist"));
        } else {
          if (response.data.isCompleted === true) {
            onHandleLoginSuccess();
          } else {
            const nextStepType = response.data.nextStepType;
            doLogin(isNewLogin).then((response) => {
              if (response.IsSuccess) { //以下动作参考 GuestContainer.handleLoginSuccess
                if (nextStepType == RegisterNextStepEnum.InitPhone) {
                  doStepPhoneValid(onHandleLoginSuccess);
                } else if (nextStepType === RegisterNextStepEnum.SetPhoneDontCareDuplicate || isRegisterShowPhone) {
                  doStepPhoneDontCare(isNewLogin, onHandleLoginSuccess);
                } else {
                  onHandleLoginSuccess();
                  // isRegisterShowPhone 别的情况
                }
              } else {
                alert(ErrorSwitcher.getTranslation(response.info));
              }
            });
          }
        }
      }
    });
  }
  const doLogin = (isNewLogin) => {
    const promise = isNewLogin
      ? AccountService.Login(formData.username, formData.password)
      : accountLogin(formData.username, formData.password);
    return promise;
  }

  //PISO使用 检查电话重复
  const doStepPhoneValid = (onHandleLoginSuccess) => {
    console.log("doStepPhoneValid");
    doInitPhone(formData.areacode, formData.phone).then((response) => {
      if (response.IsSuccess) {
        const securityKey = response.data.stepResponse;
        doCodeValidate(securityKey, "123456").then((response) => { ////暂时错误也登录
          onHandleLoginSuccess();
        });
      } else {
        alert(response.info)
      }
    })
  }

  //369VN PB PB2 使用不检查电话重复
  const doStepPhoneDontCare = (isNewLogin, onHandleLoginSuccess) => {
    console.log("doStepPhoneDontCare");

    newRegister("SetPhoneDontCareDuplicate", {
      areacode: config.features.PhonePrefix,
      phone: formData.phone,
    }).then(() => {
      if (isNewLogin) {
        try369Point();
        setTimeout(() => history.push("/"), 1000);
      } else {
        onHandleLoginSuccess();
      }
    })
  }

  /** 暂时只用于新版369登录*/
  const performLogin = async () => {
    if (!checkInfo(true)) {
      return;
    }
    AccountService.Login(formData.username, formData.password).then((response) => {
      if (response.IsSuccess) { //以下动作参考 GuestContainer.handleLoginSuccess
        history.push("/");
      } else {
        alert(ErrorSwitcher.getTranslation(response.info));
      }
    });
  }

  const states = {
    countdown,
    prefill,
    fromCustomDomain,
    haveAffiliate: general.haveAffiliate,
  }
  return { performGetCode, performNewRegister, performLogin, countdown, states };

}
