var translation = {};

const ErrorSwitcher = {
  translation,
  setTranslation: (data) => {
    translation = data;
  },
  getTranslation: (msgid) => {
    if (translation[msgid]) {
      return translation[msgid];
    } else {
      let localTranslationStr = localStorage.getItem("translation");
      if (localTranslationStr) {
        let localTranslation = JSON.parse(localTranslationStr);
        translation = localTranslation;
        return localTranslation[msgid] ?? msgid;
      }else{
        return msgid;
      }
    }
  }
};
export default ErrorSwitcher;
