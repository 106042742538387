/**
 * 来源于tgs-web-20: ui/button/
 */
import React from "react";
import styled from "styled-components";
import Icon from "../../icon";
import {version} from "../../../templates";


const CustomButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px transparent;
  border-radius: ${({ theme }) => theme.new_borderRadius};
  ${({ variant, theme }) => switchVariant(variant, theme)};
  ${({ size }) => sizeSwitcher(size)};
  ${({ disabled }) =>
  disabled
    ? `
    background: #58585a;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
    border:none;
    `
    : `
    cursor: pointer;
    `}

  max-width: ${({ maxContent }) => {
  return maxContent ? "fit-content" : "100%";
}};

  :hover {
    box-shadow: inset 0px 0px 2px 1px ${({ theme }) => theme.new_text};
  }

  ${({ color }) => {
  return `color: ${color}`;
}}
`;

const ButtonWrapper = styled.div`
  padding: ${({ margin }) => {
  return margin ? margin : "0px";
}};
`;

function switchVariant(variant, theme) {
  switch (variant) {
    case "outlined":
      return {
        background: "transparent",
        borderColor: theme.new_primary,
        color: theme.new_primary,
      };
    case "text":
      return {
        background: "transparent",
      };
    
    case "gray":
      return {
        background: "#58585a",
        color: "#fff",
        borderColor: theme.new_primary,
      };
    
    case "secondary":
      return {
        background: theme.new_secondaryBody,
        borderColor: theme.new_primary,
        color: theme.new_primary,
      };
    
    default:
      return {
        background: `linear-gradient(180deg, ${theme.new_primaryGradient}, ${theme.new_primary})`,
        borderColor: theme.new_primary,
        color: theme.new_body,
      };
  }
}

function sizeSwitcher(size) {
  switch (size) {
    case "s":
      return {
        padding: "5px 20px",
        gap: "10px",
        fontSize: "12px",
      };
    case "m":
      return {
        padding: "10px 25px",
        gap: "10px",
        fontSize: "12px",
      };
    
    default:
      return {
        padding: "14px 20px",
        gap: "20px",
        minWidth: 120,
      };
  }
}

function iconSizeSwitcher(size) {
  switch (size) {
    default:
      return;
  }
}

const Button = ({
                  label,
                  variant,
                  onClick,
                  disabled,
                  margin,
                  size,
                  maxContent,
                  icon,
                  followTextColor,
                  iconUseImage,
                  color,
                  children,
                }) => {
  
  return (
    <ButtonWrapper margin={margin}>
      <CustomButton
        maxContent={maxContent}
        disabled={disabled}
        variant={variant}
        onClick={onClick}
        size={size}
        color={color}
      >
        {icon && (
          <Icon
            icon={icon}
            size={iconSizeSwitcher(size)}
            followTextColor={variant === "text" || followTextColor}
            iconUseImage={iconUseImage}
          />
        )}
        <div> {label??children}</div>
      </CustomButton>
    </ButtonWrapper>
  );
};

export default Button;
