import styled from "styled-components";
import i18n from "../../../../../utils/i18n";
import { AppHistory } from "../../history/History";
import { connect } from "react-redux";
import {LanguagePicker} from "../../../widgets";
import {Button,Icon} from "../../../uis";
import { useState } from "react";
import { accountAction } from "../../../../../api-services/actions/accountAction";

const DrawerUpperSection = styled.div`
  padding: 60px 20px 20px 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgba(${({ theme }) => theme.new_primaryRgb}, 0.5);
`;

const LoginSection = styled.div`
  display: flex;
  gap: 10px;
  color: #000;
`;

const LogoutSection = styled.div`
  padding: 20px;
  border-top: 1px solid rgba(${({ theme }) => theme.new_primaryRgb}, 0.5);
  width: -webkit-fill-available;
`;

const VIPNumber = styled.div``;

const MemberName = styled.div`
  font-size: 25px;
`;

const TheThreeHorseman = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: center;
  margin-top: 30px;
`;

const TheHorseman = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const DrawerT20 = ({ account, accountLogout }) => {
  const [open, setOpen] = useState(false);
  const theThreeHorseman = [
    {
      name: "accountsafety",
      icon: "accountsafety.png",
      display: "transfer.transfer",
      to: "/transfer",
      requiredAuth: true,
    },
    {
      name: "deposit",
      icon: "topdeposit.png",
      display: "common.deposit",
      to: "/deposit",
      requiredAuth: true,
    },
    {
      name: "withdraw",
      icon: "transactionrecord.png",
      display: "common.withdrawal",
      to: "/withdrawal",
      requiredAuth: true,
    },
  ];
  const drawerContent = [
    {
      name: "transfer",
      icon: "new-transfer.svg",
      display: "transfer.transfer",
      to: "/transfer",
      requiredAuth: true,
    },
    {
      name: "inbox",
      icon: "new-inbox.svg",
      display: "memo",
      to: "/memo",
      requiredAuth: true,
    },
    {
      name: "affiliate",
      icon: "new-affiliate.svg",
      display: "affiliate.title",
      to: "/affiliate",
      requiredAuth: true,
    },
    {
      name: "vip",
      icon: "new-vip.svg",
      display: "VIP",
      to: "/vip",
    },
    {
      name: "contactus",
      icon: "new-cs.svg",
      display: "common.contactus",
      to: "/support",
    },
    {
      name: "download",
      icon: "new-download.svg",
      display: "common.download",
      to: "/main/download",
    },
  ];

  return (
    <>
      <DrawerUpperSection>
        <MemberName>{account?.info?.member_username}</MemberName>
        {account.token && (
          <VIPNumber>VIP{account?.info?.member_level_id}</VIPNumber>
        )}
        {!account.token && (
          <LoginSection>
            <Button
              title={i18n.t("common.login")}
              size="m"
              followTextColor
              onClick={() => {
                AppHistory.push("/login");
              }}
            />
            <Button
              title={i18n.t("common.register")}
              size="m"
              onClick={() => {
                AppHistory.replace("/login?do=register");
              }}
              gray
            />
          </LoginSection>
        )}
        <TheThreeHorseman>
          {theThreeHorseman.map((data, index) => {
            return (
              <TheHorseman
                key={index}
                onClick={() => {
                  if (data.requiredAuth && !account.token) {
                    AppHistory.push("/login");
                  } else {
                    AppHistory.push(data.to);
                  }
                }}
              >
                <Icon icon={data.icon} iconUseImage size="30" />
                <div>{i18n.t(data.display)}</div>
              </TheHorseman>
            );
          })}
        </TheThreeHorseman>
      </DrawerUpperSection>
      <div>
        {drawerContent.map((item, index) => {
          return (
            <Button
              key={index}
              variant="text"
              icon={item.icon}
              maxContent
              title={
                i18n.exists(item.display) ? i18n.t(item.display) : item.display
              }
              onClick={() => {
                if (item.requiredAuth && !account.token) {
                  AppHistory.push("/login");
                } else {
                  AppHistory.push(item.to);
                }
              }}
            />
          );
        })}
        <LanguagePicker layout={"dropdown"}></LanguagePicker>
      </div>
      {account.token && (
        <LogoutSection>
          <Button
            title={i18n.t("web.header.logout")}
            onClick={() => {
              accountLogout(account.token);
              setOpen(false);
            }}
          />
        </LogoutSection>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    accountLogout: (token) => {
      dispatch(accountAction.accountLogout(token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerT20);
