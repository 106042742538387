import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

const positionSwitcher = (position) => {
  switch (position) {
    default: {
      return {
        justifyContent: "center",
        alignItems: "center",
      };
    }
  }
};

const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  height: ${({screenHeight}) => screenHeight + 'px'};
  ${({ position }) => positionSwitcher(position)};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
`;

const Overlay = ({ open, onClose, children, position }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  const screenHeight = useSelector((state) => state.ui.screenHeight)

  return (
    <OverlayWrapper open={open} onClick={onClose} position={position} screenHeight={screenHeight}>
      {children}
    </OverlayWrapper>
  );
};

export default Overlay;
