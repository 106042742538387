import React, {useState, useContext} from "react";
import styled, {ThemeContext} from "styled-components";
import {connect} from "react-redux";
import i18n from "../../../../utils/i18n";

import {Icon, Drawer, Row, Button} from "../../uis";
import {ProfileBalance, DrawerContent, AppHistory} from "../../widgets";
import config from "../../../../config/config";

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 0 10px;
  align-items: center;
  z-index: 999;
  font-size: 20px;
  background: ${({theme, transparent}) => {
    return transparent || theme.transparentAppBar
            ? theme.new_body
            : theme.new_primary;
  }};

  color: ${({theme}) => {
    return theme.new_useAlternateColor ? theme.new_primary : theme.new_text;
  }};
`;

const IconPosition = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AnotherDummyHeader = styled(HeaderWrapper)`
  z-index: 1;
  background: ${({theme, transparent}) => {
    return theme.new_body;
  }};
`;

const DummyHeader = styled.div`
  height: 50px;
`;

const HeaderBack = styled.div`
  width: 50px;
  height: 50px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackButotn = styled(IconPosition)`
  width: 50px;
  height: 50px;
  position: fixed;
  ${({theme}) => {
    return theme.new_backButtonPosition === "end" ? "right: 0" : "left: 0";
  }};
`;

const DrawerPosition = styled(IconPosition)`
  ${({theme}) => {
    return theme.new_drawerPosition === "left" ? "order: -1" : "order: 1";
  }};
`;

const LogoContainer = styled.div`
  flex: 1;
`;

const Logo = styled.img`
  height: 30px;
`;

const LoggedIn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const BalanceText = styled.div`
  font-size: 12px;
`;

const AppBar = ({
                  title,
                  withBack,
                  transparent,
                  customBack,
                  drawer,
                  general,
                  account,
                }) => {
  const {brand} = general;
  const theme = useContext(ThemeContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const isInHome =
    window.location.pathname === "/" ||
    window.location.pathname === "/main" ||
    window.location.pathname === "/main/home";
  const isInProfile = window.location.pathname === "/main/profile";
  const isInNews = window.location.pathname.split("/")[1] === "news";

  const actionContent = [
    {
      name: "login",
      display: "common.login",
      to: "/login",
      icon: "login.png",
      background: theme.new_secondary,
    },
    {
      name: "register",
      display: "common.register",
      to: "/login?do=register",
      icon: "register.png",
      background: theme.new_tertiary,
    },
  ];

  const memberActionVaraintTwo = () => {
    return (
      <Row gap="10px">
        <Row alignItem="center">
          <Icon
            icon={config.wapVersion + "/coins.png"}
            iconUseImage
            size="15"
          />
          <BalanceText>
            <ProfileBalance textOnly/>
          </BalanceText>
        </Row>
        <Icon
          icon={config.wapVersion + "/plus.png"}
          iconUseImage
          size="25"
          onClick={() => {
            AppHistory.push("/deposit");
          }}
        />
        <Icon
          icon={config.wapVersion + "/minus.png"}
          iconUseImage
          size="25"
          onClick={() => {
            AppHistory.push("/withdrawal");
          }}
        />
      </Row>
    );
  };

  const memberActionVaraintOne = () => {
    return (
      <>
        <Icon icon="people.png" iconUseImage size="40"/>
        <LoggedIn>
          {account?.info?.member_username}
          <BalanceText>
            <ProfileBalance textOnly/>
          </BalanceText>
        </LoggedIn>
      </>
    );
  };

  const memberActionSwicher = () => {
    switch (theme.new_memberActionVariant) {
      case 1:
        return memberActionVaraintOne();
      case 2:
        return memberActionVaraintTwo();
      default:
        return memberActionVaraintOne();
    }
  };

  const appbarActionVariantOne = (item, index) => {
    return (
      <Button
        key={index}
        title={i18n.t(item.display)}
        size="m"
        icon={theme.new_appbarActionShowIcon ? item.icon : ""}
        followTextColor
        iconUseImage
        onClick={() => {
          AppHistory.push(item.to);
        }}
        gray
        withBg={item.background}
      ></Button>
    );
  };

  const appbarActionSwitcher = (item, index) => {
    switch (theme.new_appbarActionVariant) {
      default:
        return appbarActionVariantOne(item, index);
    }
  };

  return (
    <>
      <HeaderWrapper transparent={transparent}>
        {withBack && (
          <BackButotn
            onClick={() => {
              if (customBack) {
                AppHistory.push(customBack);
              } else {
                AppHistory.goBack();
              }
            }}
          >
            {theme.new_useAlternateColor ? (
              <Icon icon={theme.new_backButton || "back-0.png"}/>
            ) : (
              <Icon
                icon={theme.new_backButton || "back-0.png"}
                followTextColor
              />
            )}
          </BackButotn>
        )}{" "}
        {isInHome && (drawer || theme.new_appbarShowDrawer) && (
          <DrawerPosition
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            <Icon
              icon={theme.new_drawerButton || "hamburger-0.png"}
              size="25"
              iconUseImage
            />
          </DrawerPosition>
        )}
        {title ? (
          <Row justifyContent="center" flex="1">
            {title}
          </Row>
        ) : (
          <LogoContainer>
            <Logo src={brand.mobileLogoUrl} alt="logo"/>
          </LogoContainer>
        )}
        {((isInHome && theme.new_appbarShowAction) ||
          (isInProfile && theme.new_showmemberAction) ||
          (isInNews && theme.new_showmemberAction)) && (
          <Row gap="10px">
            {account.token ? (
              memberActionSwicher()
            ) : (
              <>
                {actionContent
                  .filter((item) => {
                    return (
                      theme.new_appbarActionDisplay.indexOf(item.name) !== -1
                    );
                  })
                  .map((item, index) => {
                    return appbarActionSwitcher(item, index);
                  })}
              </>
            )}
          </Row>
        )}
      </HeaderWrapper>
      <DummyHeader/>
      <AnotherDummyHeader/>
      <Drawer
        position={theme.new_drawerPosition}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <DrawerContent></DrawerContent>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    general: state.general,
    account: state.account,
  };
};

export default connect(mapStateToProps, null)(AppBar);
