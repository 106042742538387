import { useSelector } from "react-redux";

import i18n from "../../../../../utils/i18n";
import config from "../../../../../config/config";

export default function () {
  const playerIdentityAuthenticationEnabled = useSelector(state => state.general.brand?.playerIdentityAuthenticationEnabled)
  const AccountInfo =useSelector(state => state.account.info)
  
  const baseInfoList = [
    {
      show: config.features.ICVerification,
      comProps: {
        title: i18n.t("profile.nameVerify.icNum"),
        label: i18n.t("profile.nameVerify.title"),
        attr: "member_name",
        edit: true,
        icon: process.env.PUBLIC_URL + `/assets/images/wapp/icon-user.png`,
        playerIdentityAuthenticationEnabled,
        path: "/profile/name"
      }
    }, {
      show: (
        config.features.SMSVerification ||
        config.features.PromoRoulette_SMSVerification ||
        config.features.Register_ShowPhone ||
        config.features.ForceShowPhoneInProfile
      ),
      comProps: {
        title: i18n.t("profile.phone.title"),
        label: i18n.t("common.phoneNumber"),
        attr: "member_phone",
        edit: !config.features.Profile_DisableEditPhone,
        disable: config.features.Profile_DisableVerifyPhone,
        icon: process.env.PUBLIC_URL + `/assets/images/wapp/icon-phone.png`,
        path: "/profile/phone"
      }
    }, {
      show: config.features.EmailVerification || config.features.Register_ShowEmail,
      comProps: {
        title: i18n.t("common.emailAddress"),
        label: i18n.t(config.brand + '.profile.emailAddress', { defaultValue: i18n.t("common.emailAddress") }),
        attr: "member_email",
        icon: process.env.PUBLIC_URL + `/assets/images/wapp/icon-email.png`,
        path: "/profile/email"
      }
    }, {
      show: true,
      comProps: {
        title: i18n.t("profile.birthdate.title"),
        label: i18n.t("profile.birthdate.title"),
        attr: "member_birthdate",
        path: "/profile/birthdate",
        icon: process.env.PUBLIC_URL + `/assets/images/wapp/icon-birthdate.png`,
      }
    }
  ]

  const getStatus = () => {
    if (!playerIdentityAuthenticationEnabled) return ""

    const currentFormStatus = AccountInfo['identityformstatus']
    const hasSubmitted = AccountInfo['identityformid'] ? true : false

    if (currentFormStatus === 0 ) {
      return hasSubmitted ? "verifying" : "no"
    }
    return currentFormStatus === 1 ? "yes" : currentFormStatus === 2 ? "rejected" : ""

  }

  const getMemberNameStatus = () => {
    return getStatus()
    
  };

  const getComType = () => {
    const obj = {
      "verifying": "waiting-verification",
      "no": "waiting-submit",
      "yes": "approved",
      "rejected": "rejected"
    }
    let status = obj[getStatus()] 
    if (!status && !playerIdentityAuthenticationEnabled && AccountInfo['member_name']) {
      status = "approved"
    }
    return status ? status : ""
  }


  const getBaseInfoValue = (key) => {
    const value = AccountInfo[key]
    if (key === 'member_name') {
      return playerIdentityAuthenticationEnabled ? `${value} (${i18n.t("verified." + getMemberNameStatus())})` : value ?? i18n.t(config.brand+'.profile.notYetVerified',{defaultValue: i18n.t("profile.notVerify")});
    }
    if (key === 'member_phone') {
      return value ? value.substring(0, 1) + "**" : ''
    }
    return value ?? ''
  }

  return {
    getComType,
    getBaseInfoValue,
    baseInfoList,
    playerIdentityAuthenticationEnabled
  }

}