/** 代码来源于 wap-t1:ui,如何有其他template用到,要像button那样做兼容  */
import React from "react";
import styled from "styled-components";
import DisabledOverlay from "../disabledoverlay";

const InputWrapperWrapper = styled.div`
  background: ${({ theme, backgroundFollowBody, transparent }) => {
  return transparent
    ? "transparent"
    : backgroundFollowBody
      ? theme.new_secondaryBody
      : theme.new_textfieldBackground;
}};
  padding: 15px 0;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  gap: 10px;
  width: ${({ width }) => {
  return width ? width : "";
}};

  flex: ${({ flex }) => {
  return flex ? flex : "";
}};

  min-height: ${({ theme }) => theme.new_textfieldHeight};
  max-height: ${({ forcedHeight, theme }) => {
  return forcedHeight ? theme.new_textfieldHeight : "";
}};
  border-radius: ${({ theme }) => theme.new_borderRadius};

  .MuiInputBase-input {
    padding: 0;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0 !important;
  }

  input[type="password"] {
    letter-spacing: 3px;
  }

  input,
  textarea,
  select {
    border: none;
    background: transparent;
    display: flex;
    outline: none;
    flex: 1;
    color: ${({ theme }) => theme.new_textfieldText};
    &::-webkit-input-placeholder {
      color: ${({ theme }) => theme.new_textfieldPlaceholder};
      letter-spacing: initial;
    }

    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

    option {
      color: #000;
    }
  }
`;

const InputWrapper = ({
                        children,
                        backgroundFollowBody,
                        width,
                        transparent,
                        onClick,
                        flex,
                        disabled,
                        forcedHeight,
                      }) => {
  return (
    <InputWrapperWrapper
      backgroundFollowBody={backgroundFollowBody}
      width={width}
      transparent={transparent}
      onClick={onClick}
      flex={flex}
      forcedHeight={forcedHeight}
    >
      {disabled && <DisabledOverlay />}
      {children}
    </InputWrapperWrapper>
  );
};

export default InputWrapper;
