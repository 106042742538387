import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {generalAction} from "../../../api-services/actions/generalAction";

export function useGeneralStore() {
  const dispatch = useDispatch();
  const getInvitationCode = useCallback(
    async () => {
      return dispatch(generalAction.getInvitationCode());
    });

  return {
    getInvitationCode,
  };
}

