/** 代码来源于 wap-t1:ui */
import React from "react";
import styled from "styled-components";

const DisabledOverlayContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: ${({ theme }) => {
  return `rgba(${theme.new_textRgb}, 0.3)`;
}};
  z-index: 1;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 2;
`;

const DisabledOverlay = ({ children }) => {
  return <DisabledOverlayContainer>{children}</DisabledOverlayContainer>;
};

export default DisabledOverlay;
