/**
 * 代码来源于tgs-web-t20: ui/textfield
 */
import React from "react";
import styled from "styled-components";
import Input from "../../input";
import Icon from "../../icon";

const TextfieldWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  gap: 20px;
  border-radius: ${({ theme }) => theme.new_borderRadius};
  border: solid 1px transparent;

  border: solid 1px transparent;
  transition: border 0.2s ease-in-out;
  ${({ variant, theme }) => switchVariant(variant, theme)};
  :hover {
    border: solid 1px rgba(${({ theme }) => theme.new_primaryRgb}, 0.7);
  }
`;

const LeftSide = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

const TheTextfield = styled.input`
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.new_textfieldText};
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.new_textfieldPlaceholder};
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: ${({ theme }) => theme.new_textfieldText};
  }
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const DisabledOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgb(0, 0, 0);
  opacity: 0.2;
  z-index: 1;
  border-radius: 8px;
`;

function switchVariant(variant, theme) {
  switch (variant) {
    case "gray":
      return {
        background: "#58585a",
        color: theme.new_textfieldText,
      };

    case "slim": {
      return {
        padding: 5,
        background: theme.new_textfieldBackground,
      };
    }

    default:
      return {
        background: theme.new_textfieldBackground,
      };
  }
}

const Textfield = ({
  icon,
  value,
  onChange,
  type,
  placeholder,
  maxLength,
  disabled,
  action,
  label,
  onClick,
  remark,
  inline,
  variant,
  required,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Input label={label} remark={remark} inline={inline} required={required}>
      <TextfieldWrapper
        variant={variant}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {disabled && <DisabledOverlay />}
        <LeftSide>
          {inline && <div>{label}</div>}
          {icon && <Icon icon={icon} inInput value={value ? value : ""} />}
          <TheTextfield
            disabled={disabled}
            type={
              type === "password" ? (showPassword ? "text" : "password") : type
            }
            value={value}
            placeholder={placeholder}
            autoComplete="off"
            onChange={(e) => {
              if (onChange) {
                if (maxLength) {
                  if (
                    e.target.value.length <= maxLength ||
                    e.target.value.length < value.length
                  ) {
                    onChange(e);
                  }
                } else {
                  onChange(e);
                }
              }
            }}
          />
        </LeftSide>
        {type === "password" && (
          <Icon
            icon={showPassword ? "hide-password.png" : "show-password.png"}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          />
        )}
        {action && action}
      </TextfieldWrapper>
    </Input>
  );
};

export default Textfield;
