/** 代码来源 wap-t1:ui */
import React from "react";
import styled from "styled-components";
import {scalePx} from "../../templates";

const FormWrapperContainer = styled.div`
  ${({ theme }) => {
  return (
    theme.new_formContainBackground &&
    `
      background: ${theme.new_secondaryBody};
      margin: ${scalePx(10)};
      border-radius: ${theme.new_borderRadius};
    
    
    `
  );
}}
`;

const FormWrapper = ({ children }) => {
  return <FormWrapperContainer>{children}</FormWrapperContainer>;
};

export default FormWrapper;
