import React from "react";
import {isMobileOnly} from "react-device-detect";
import styled from "styled-components";

const RowWrapper = styled.div`
  display: flex;
  align-items: ${({alignItem}) => alignItem};
  justify-content: ${({justifyContent}) => justifyContent};
  gap: ${({gap}) => gap || (isMobileOnly ? 5 : 20)}px;
  flex: ${({flex}) => flex || (isMobileOnly ? "unset" : 1)};
  padding: ${({padding}) => padding || "0px"};
  ${isMobileOnly ? "" : "width: 100%"};
`;

const Row = ({ alignItem, justifyContent, children, gap, flex, padding }) => {
  return (
    <RowWrapper
      flex={flex}
      alignItem={alignItem}
      justifyContent={justifyContent}
      gap={gap}
      padding={padding}
    >
      {children}
    </RowWrapper>
  );
};

export default Row;
