/**
 * 公共模块版型处理工具
 */
import {isMobileOnly} from "react-device-detect";
import config from "../../../../config/config";


if(!config.template){
  throw new Error("error: config template not set!");
}
/**
 *
 * 获取pc版使用的Account子模块版本
 * 版型说明中的: P1-P4
 */
const getPcAccountVersion = () => {
  let ver = null;
  if (!isMobileOnly) {
    const template = config.template;
    if (['t1', 't3', 't10'].includes(template)) {
      ver = 1;
    } else if (template === 't2') {
      ver = 2;
    } else if (template === 't8') {
      ver = 3;
    } else if (['t20'].includes(template)) {
      ver = 4;
    }
  }
  return ver;
};

/**
 *
 * 获取手版使用的Wap子模块版本
 * 版型说明中的: P1-P4
 */
const getWapVersion = () => {
  let ver = 0;
  if (isMobileOnly) {
    const template = config.template;
    if (['t1', 't3', 't10'].includes(template)) {
      ver = 1;
    } else if (template === 't2') {
      ver = 2;
    } else if (['t20'].includes(template)) {
      ver = 4;
    }
  }
  return ver;
}
const wapVersion = getWapVersion();
const pcAccountVersion = getPcAccountVersion();

const version = isMobileOnly ? wapVersion : pcAccountVersion;

const isPcAccountV1 = pcAccountVersion === 1;
const isPcAccountV4 = pcAccountVersion === 4;

const isWapV1 = wapVersion === 1;
const isWapV4 = wapVersion === 4;
const isWapV2 = wapVersion === 2;

/** 查看当前代码处理什么版本,调试时使用 */
const whoAmI = () => {
  console.log("===== whoAmI =====");
  console.log("template:" + config.template);
  // console.log("wapVersion:" + config.wapVersion);
  if (isMobileOnly) {
    console.log("wapVersion:" + wapVersion);
  } else {
    console.log("pcAccountVersion:" + pcAccountVersion);
  }
  console.log("version:" + version);
  console.log("===== end whoAmI ===== ");
}
 

export {
  whoAmI,
  version,
  isPcAccountV1,
  isPcAccountV4,
  isWapV1,
  isWapV4,
  isWapV2
}
