import React from "react";
import config from "../../../../config/config";
import DrawerT20 from "./t20";

const DrawerContent = () => {
  const drawercontent = () => {
    switch (config.wapVersion) {
      default:
        return <DrawerT20 />;
    }
  };
  return <>{drawercontent()}</>;
};

export default DrawerContent;
