import React, {useContext} from "react";
import {RegisterContext} from "../../../contexts";

export const useRegisterHandle = () => {
  const {formData, setFormData} = useContext(RegisterContext);
  const handleChangeUserName = (e) => {
    setFormData({...formData, username: e.target.value});
  };

  const handleChangePwd = (e) => {
    setFormData({...formData, password: e.target.value});
  };

  const handleChangePwd2 = (e) => {
    setFormData({...formData, password2: e.target.value});
  };

  const handleChangePhone = (e) => {
    setFormData({...formData, phone: e.target.value});
  };

  const handleChangePromotion = (e) => {
    setFormData({...formData, promotionId: e.target.value});
  };
  const handleChangeUpper = (e) => {
    setFormData({...formData, upper: e.target.value});
  };

  const handleTncClick = () => {
    setFormData({...formData, tnc: !formData.tnc});
  };

  const handleChangeAreaCode = (e) => {
    setFormData({...formData, areacode: e.target.value});
  }
  const handleChangeCode = (e) => {
    setFormData({...formData, code: e.target.value});
  }

  const handleChangePromoteType = (e) => {
    setFormData({...formData, promoteType: e.target.value, upper: "", promotionId: ""});
  }

  return {
    handleChangeUserName, handleChangePwd, handleChangePwd2, handleChangePhone, handleChangePromotion,
    handleTncClick, handleChangeAreaCode, handleChangeUpper, handleChangeCode, handleChangePromoteType
  };

}
