
// 自定義的 GroupCode，可依照需求新增對應類型
// 前台會依照此順序顯示
export const GroupCodeEnum = {
  GCash:'GCash',
  PayMaya:'PayMaya',
  Bank: 'Bank',
  Grabpay:'Grabpay',
  Coinsph:'Coinsph',
  FPS:'FPS',
  OMNI:'OMNI',
  ETHEREUM:'ETHEREUM',
  ALI2ALI:'ALI2ALI',
  TRON:'TRON',
  ViettelPay:'ViettelPay',
  Momo:'Momo',
  TRC: 'TRC',
  Pulsa: 'Pulsa',
  EWallet: 'E-wallet',
  GcashNative:'Gcash Native',
  MayaNative:'Maya Native',
  GrabeNative:'Grabe Native',
  MANDIRI:'MANDIRI',
  DANA:'DANA',
  BCA:'BCA',
  QRIS:'QRIS',
}

// 自定義的 MovnCode
export const MovnCodeEnum = {
  NET:'NET', // 網關
  QR : 'QR', // 掃碼
  DR : 'DR', // 直聯
  MOMO : 'MOMO', // MOMO
  VP : 'VP', // ViettelPay
  USDT :'USDT', // USDT
}

export const DepositPromotionEnum = {
  FirstDeposit : "FirstDeposit",
  DailySecondDeposit : "DailySecondDeposit",
  DailyFirstDeposit : "DailyFirstDeposit",
  DepositReward : "DepositReward",
  DepositRewardV2 : "DepositRewardV2",
  DepositAccumulateRoulette : "DepositAccumulateRoulette",
  AccountFirstDeposit : "AccountFirstDeposit",
}

export const RegisterNextStepEnum = {
  SetPhoneDontCareDuplicate: "SetPhoneDontCareDuplicate",
  InitPhone: "InitPhone",
};