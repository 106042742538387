import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {generalAction} from "../../../api-services/actions/generalAction";
import {accountAction} from "../../../api-services/actions/accountAction";
import {AccountService} from "../../../api-services/services/AccountService";
import i18n from "../../../utils/i18n";
import {useNotifications} from "../hooks";

export function useRegisterStore() {
  // let {alert} = useNotifications({enqueueSnackbar});
  const dispatch = useDispatch();
  const getInvitationCode = useCallback(
    async () => {
      return dispatch(generalAction.getInvitationCode());
    });

  const register = useCallback(
    async (username, pwd, confirmpwd, invitationcode, upper) => {
      return dispatch(accountAction.registerAccount(username, pwd, confirmpwd, invitationcode, upper));
    }, []);

  const accountLogin = useCallback(
    async (username, password) => {
      return dispatch(accountAction.accountLogin(username, password));
    }, []);

  const newRegister = useCallback(
    async (type, input) => {
      return dispatch(accountAction.newRegister(type, input));
    }, []);

  const doInitPhone = useCallback(
    async (areacode, phone) => {
      return  AccountService.NewRegister("InitPhone", {
        input: "Init",
        data: {
          areacode: areacode,
          phone: phone,
        },
      });
      //
      // let isSuccess = false;
      // let securityKey = null;
      // AccountService.NewRegister("InitPhone", {
      //   input: "Init",
      //   data: {
      //     areacode: areacode,
      //     phone: phone,
      //   },
      // }).then(async (response) => {
      //
      //   isSuccess = response.IsSuccess;
      //   if (response.IsSuccess) {
      //     securityKey = response.data.stepResponse;
      //     alert(i18n.t("common.msgTokenSent"));
      //   } else {
      //     alert(response.info);
      //   }
      // });
      // return {isSuccess, securityKey};
    }, []);

  const doCodeValidate = useCallback(async (securityKey, code) => {
    return AccountService.NewRegister("InitPhone", {
      input: "Valid",
      data: {
        securitykey: securityKey,
        validatecode: code,
      },
    });


    // let isSuccess = false;
    //
    // AccountService.NewRegister("InitPhone", {
    //   input: "Valid",
    //   data: {
    //     securitykey: securityKey,
    //     validatecode: code,
    //   },
    // }).then(async (response) => {
    //   console.log("doCodeValidate.response",response);
    //   isSuccess = response.IsSuccess;
    //   if (response.IsSuccess) {
    //     alert(i18n.t("common.msgTokenVerified"));
    //   } else {
    //     console.log("doCodeValidate.IsError",response.info);
    //
    //     alert(response.info);
    //   }
    // });
    // return {isSuccess};
  }, []);

  return {getInvitationCode, register, accountLogin, newRegister, doInitPhone, doCodeValidate}
}