import React from "react";
import styled from "styled-components";

const Label = styled.div`
  font-size: 16px;
`;

const CustomTextField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Remark = styled.div`
  font-size: 12px;
  padding-top: 5px;
  color: ${({ theme }) => theme.new_primary};
  opacity: 0.75;
`;

const Input = ({ children, label, inline, remark, required }) => {
  return (
    <CustomTextField>
      {!inline && label && (
        <Label>
          {label}
          {required && <span style={{ color: "red" }}> *</span>}
        </Label>
      )}
      {children}
      {remark && <Remark>{remark}</Remark>}
    </CustomTextField>
  );
};

export default Input;
