/**
 * 来源于tgs-web-t1: Wap/Share/Button
 */
import React, {Component} from "react";
import styled from "styled-components";

import {scalePx} from "../../../templates";

const Content = styled.div`
  ${(props) => {
    if (props.theme.buttonBg) {
      return props.disabled ?
              `background-color: #A5A5A5;` :
              `background-color: ${props.theme.buttonBg};`
    }
    return props.disabled ? `background-color: #A5A5A5;` : `background-color: ${
            props.theme.wapPrimary ? props.theme.wapPrimary : props.theme.primary
    }`
  }
  };
  height: ${(props) => scalePx(47, props.fix)};
  border-radius: ${(props) => scalePx(10, props.fix)};
  font-size: ${(props) => scalePx(17, props.fix)};
  padding: ${(props) => scalePx(11, props.fix)} 0;
  color: ${({theme}) => theme.customButtonText ? theme.customButtonText : "#ffffff"};
  text-align: center;
`;

const ButtonWrapper = styled.div`
`;

class CustomButton extends Component {
  
  render() {
    return (
      <ButtonWrapper>
        <Content fix={this.props.fix}
                 onClick={this.props.disabled === true ? null : this.props.onClick}
                 disabled={this.props.disabled ? this.props.disabled : false}
        >
          {this.props.label ?? this.props.children}
        </Content>
      </ButtonWrapper>
    );
  }
}

export default CustomButton;
