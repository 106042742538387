import React from "react";
import {useState} from "react";
import styled from "styled-components";
import i18n from "../../../../../utils/i18n";
import {useEffect} from "react";
import Input from "../../input";
import InputWrapper from "../../inputwrapper";
import config from "../../../../../config/config";

const LeftSide = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 15px 0;
`;

const Button = styled.div`
  background: ${({theme, disabled}) =>
          disabled ? "#848484" : theme.new_primary};
  padding: 2.5px 5px;
  text-wrap: nowrap;
  min-width: fit-content;
  border-radius: ${({theme}) => theme.new_borderRadius};
  color: ${({theme}) => {
    return theme.primaryHighContrast ? "#000" : theme.new_text;
  }};
`;

const Verification = (
  {
    value,
    placeholder,
    countdown,
    onChange,
    onClick,
  }) => {
  return (
    <Input >
      <InputWrapper forcedHeight>
        <LeftSide>
          <input value={value} placeholder={placeholder} onChange={onChange}/>
        </LeftSide>
        <Button
          disabled={countdown > 0}
          onClick={onClick}
        >
        {countdown
          ? i18n.t("common.btnTokenResendLabel") + "(" + countdown + "s)"
          : i18n.t("common.msgSendToken")}
        </Button>
      </InputWrapper>
    </Input>
  );
};

export default Verification;
