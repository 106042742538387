import React from "react";
import styled from "styled-components";
import {styleGlobal} from "../../../templates"

const Label = styled.div`
  color: ${({ theme }) => theme.new_textfieldText};
`;

const CustomTextField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Remark = styled.div`
  padding-top: 5px;
  color: ${({ theme }) => styleGlobal.remarkText};
`;

const Input = ({ children, label, inline, remark, required }) => {
  return (
    <CustomTextField>
      {!inline && label && (
        <Label>
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </Label>
      )}
      {children}
      {remark && <Remark>{remark}</Remark>}
    </CustomTextField>
  );
};

export default Input;
