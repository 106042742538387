import React from "react";
import styled from "styled-components";
import Row from "../row";
import Icon from "../icon";

const ProfileWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProfileWrapperContent = styled.div`
  background: ${({ theme }) => theme.new_secondaryBody};
  padding: ${({ padding }) => padding || "20px"}
  width: 100%;
  gap: 20px;
  padding: 20px; min-height: 500px;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.new_borderRadius};
`;

const ProfileWrapper = ({ icon, title, children, padding }) => {
  return (
    <ProfileWrapperContainer>
      {(icon || title) && (
        <Row>
          {icon && <Icon icon={icon} followTextColor />}
          {title && <div>{title}</div>}
        </Row>
      )}

      <ProfileWrapperContent padding={padding}>
        {children}
      </ProfileWrapperContent>
    </ProfileWrapperContainer>
  );
};

export default ProfileWrapper;
