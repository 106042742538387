import {isMobileOnly} from "react-device-detect";
import styled from "styled-components";
import {scalePx} from "../../templates";


export const DialogTitle = styled.div`
  font-size: ${({fix}) => scalePx(13, fix)};
  font-weight: 700;
  color: ${({ theme }) => theme.secondary};
  margin-bottom: 20px;
  text-align: center;
`;
export const DialogContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({fix}) => scalePx(14, fix)};
  font-weight: 400;
  color: ${({ theme }) => theme.text};
  margin-bottom: ${({fix}) => scalePx(20, fix)};
  line-height: 1.2em;
`;

export const DialogContainer = styled.div`
  width: ${({fix}) => scalePx(300, fix)}; //测试pc时加的
  padding: ${({fix}) => scalePx(20, fix)};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`;

export const TwoButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;

  & > * {
    flex: 1;
  }
`;
