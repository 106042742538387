import React from "react";
import styled from "styled-components";
import {primaryColor, textPrimaryColor} from "../../templates";


const IconWrapper = styled.div`
  display: ${({ inInput, theme }) => {
    if (inInput) {
      return theme.new_inputShowIcon ? "block" : "none";
    } else {
      return "block";
    }
  }};

  ${({
    iconUseImage,
    icon,
    theme,
    inInput,
    followTextColor,
    customBackground,
  }) => {
    const iconPath = process.env.PUBLIC_URL + "/assets/images/iconpack/" + icon;
    return !iconUseImage
      ? `
      mask: url(${iconPath})
        no-repeat center / contain;
      background: ${
        inInput || textPrimaryColor()
      };
    `
      : `
      background: url(${iconPath})
        no-repeat center / contain;
    
   
    
      
      `;
  }}
  background-size: 100%;
  opacity ${({ inInput, value }) => {
    return inInput ? (value.length > 0 ? "1" : "0.5") : "1";
  }};
  min-width: ${({ size }) => (size ? `${size}px` : "20px")};
  height: ${({ size }) => (size ? `${size}px` : "20px")};

  ${({ withBg }) => {
    return withBg ? `background: ${withBg}` : "";
  }}
`;

const Icon = ({
  icon,
  onClick,
  inInput,
  value,
  followTextColor,
  size,
  iconUseImage,
  withBg,
  commonIconName
}) => {
  return (
    <IconWrapper
      icon={icon}
      onClick={onClick}
      inInput={inInput}
      value={value}
      followTextColor={followTextColor}
      size={size}
      iconUseImage={iconUseImage}
      withBg={withBg}
      commonIconName={commonIconName}
    />
  );
};

export default Icon;
