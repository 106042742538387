import React from "react";
import Common from "../../../../utils/Common";
import {connect} from "react-redux";
import styled from "styled-components";
import {Row, Icon} from "../../uis";

import {walletAction} from "../../../../api-services/actions/walletAction";

const BalanceLabel = styled.div`
  background: ${({theme}) => theme.new_primary};
  font-size: 20px;
  text-align: center;
  color: #fff;
  padding: 12px;
`;

const TextOnly = styled.div`
  color: ${({theme, followTextColor}) =>
          followTextColor ? theme.new_text : theme.new_primary};
`;

const ProfileBalance = ({
                          game,
                          wallet,
                          textOnly,
                          followTextColor,
                          getGameBalance,
                          updateBalanceList,
                          withRefresh,
                        }) => {
  const [clicked, setClicked] = React.useState(false);

  const performBalanceRefresh = () => {
    setClicked(true);
    updateBalanceList();
    game.providerList
      .filter((item) => item.status === 0 && item.transferwallet === true)
      .filter(
        (item) =>
          item.gpcode !== "NTTF" &&
          item.gpcode !== "RTTF" &&
          item.gpcode !== "BTG" &&
          item.gpcode !== "NLC"
      )
      .map((a) => getGameBalance(a.gpcode));

    setTimeout(() => {
      setClicked(false);
    }, 1000);
  };

  const balance = Common.formatNumberWithThousand(
    game.providerList.reduce(
      (total, item) =>
        item.transferwallet === true ? (total += item.balance) : (total += 0),
      0
    ) + wallet.mainBalance
  );
  return textOnly ? (
    <Row alignItem="center">
      <TextOnly followTextColor={followTextColor}>{balance}</TextOnly>
      {withRefresh && (
        <Icon
          icon="refresh.png"
          followTextColor={followTextColor}
          onClick={performBalanceRefresh}
          spin={clicked}
        />
      )}
    </Row>
  ) : (
    <BalanceLabel>{balance}</BalanceLabel>
  );
};

const mapStateToProps = (state) => {
  return {
    game: state.game,
    wallet: state.wallet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGameBalance: (gpcode) => dispatch(walletAction.getGameBalance(gpcode)),
    updateBalanceList: (loading) => dispatch(walletAction.updateBalanceList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBalance);
